import { memo } from "react";
import {
  Container,
  Category,
  Services,
  NamePrice,
  Description,
  ReadMore,
  ButtonContainer,
  Duration,
} from "./style";

import Button from 'react-bootstrap/Button';

import defaultServiceImage from "../../assets/default-service.webp";

function ServicesSection({
  readMore,
  handleReservation,
  isChoosingMoreServices,
  servicesData
}) {

  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  const converterTempoParaTexto = (tempoEmMinutos) => {
    const horas = Math.floor(tempoEmMinutos / 60);
    const minutos = tempoEmMinutos % 60;
  
    if (horas === 0) {
      return `${minutos} minuto${minutos !== 1 ? 's' : ''}`;
    } else if (minutos === 0) {
      return `${horas} hora${horas !== 1 ? 's' : ''}`;
    } else {
      return `${horas} hora${horas !== 1 ? 's' : ''} e ${minutos} minuto${minutos !== 1 ? 's' : ''}`;
    }
  };

  return (
    <Container>
      <Category key={0}>
        <h1 style={{color: "var(--main-color-1)"}} >SERVIÇOS</h1>
        <Services>
          {servicesData?.map((service) => {
            return (
              <div key={service?.id} className='col-lg-12 mb-4'>
                <div className='card rounded-0'>
                  <img style={{borderRadius: '0px', height: '300px', objectFit: "cover"}} src={service?.image?.url ? service?.image?.url : defaultServiceImage} className='card-img-top'/>
                  <div className='card-body'>
                    <div>
                      <NamePrice>
                        <p style={{fontWeight: "bold"}}>{service?.title}</p>
                        <p>{`${numberFormat(service?.price)}`}</p>
                      </NamePrice>
                    </div>
                    {service.description && (<div>
                      <Description>{service?.description}</Description>
                      {service?.description !== "" && service.description !== null && service.description.length > 100 && (
                        <ReadMore onClick={() => readMore(service)}>
                          Ler mais...
                        </ReadMore>
                      )}
                    </div>)}
                    <ButtonContainer>
                      <Duration>{converterTempoParaTexto(service?.duration)}</Duration>
                      <Button variant="outline-dark" onClick={() => handleReservation(service)}>
                        {isChoosingMoreServices ? "Adicionar" : "Agendar"}
                      </Button>
                    </ButtonContainer>
                  </div>
                </div>
              </div>
            );
          })}
        </Services>
      </Category>
    </Container>
  );
}

export default memo(ServicesSection);
