import { useState, createContext, useContext, useCallback } from "react";
import * as api from "../services/api";

const UserContext = createContext();

export function UserContextProvider(props) {
  const [apiToken, setApiToken] = useState(localStorage.getItem("apiToken"));
  const [customerToken, setCustomerToken] = useState(localStorage.getItem("customerToken"));
  const [loadingUserValidation, setLoadingUserValidation] = useState(true);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [userIsNewUser, setUserIsNewUser] = useState(false);
  const [userData, setUserData] = useState();
  const [businessData, setBusinessData] = useState();
  const [personalizationData, setpersonalizationData] = useState();
  const [slugData, setSlugData] = useState();
  const [homeAboutData, setHomeAboutData] = useState();
  const [homeProfessionalsData, setHomeProfessionalsData] = useState();
  const [homeServicesData, setHomeServicesData] = useState();
  const [homePriceServicesData, setHomePriceServicesData] = useState();
  const [servicesData, setServicesData] = useState();
  const [productsData, setProductsData] = useState();
  const [aboutData, setAboutData] = useState();
  const [feedbacksData, setFeedbacksData] = useState();
  const [contactData, setContactData] = useState();
  const [businessAvailabilityData, setBusinessAvailabilityData] = useState();
  const [professionalsData, setProfessionalsData] = useState();
  const [useChabotBot, setUseChatBot] = useState();
  

  async function validateApiToken(apiToken) {

    if(apiToken){

      const response_api_token = await api.validateApiToken(apiToken);

      if(response_api_token && response_api_token.code == 200){
        await validateCustomerToken(customerToken);
        return;
      }else{

        const response_api_token_renew = await api.createApiToken();

        if(response_api_token_renew && response_api_token_renew.token){
          localStorage.setItem("apiToken", response_api_token_renew.token);
          setApiToken(response_api_token_renew.token);
          await validateCustomerToken(customerToken);
          window.location.reload();
          return;
        }
      }
    }else{
      const response_api_token = await api.createApiToken();

      if(response_api_token && response_api_token.token){
        localStorage.setItem("apiToken", response_api_token.token);
        setApiToken(response_api_token.token);
        await validateCustomerToken(customerToken);
        return;
      }
    }

    localStorage.removeItem("apiToken");
    setApiToken(null);
    return;
  }

  async function validateCustomerToken(customerToken){

    if(customerToken){

      const response_user = await api.validateCustomerToken(customerToken, apiToken);

      console.log("validate customer token");

      if (response_user.code === 200) {
        
        if(businessData && businessData.id != response_user.customer.business_establishment_id){
          await removeCustomerData();
          return null;
        }else{
          setUserIsLoggedIn(true);
          setUserData(response_user.customer);
          setLoadingUserValidation(false);
          // if (apiToken.customer.newUser) setUserIsNewUser(true);
          localStorage.setItem("customerToken", customerToken);
          return response_user.customer;
        }
      }
    }

    setUserIsLoggedIn(false);
    setLoadingUserValidation(false);
    return null;
  }

  async function removeCustomerData(){
    setUserIsLoggedIn(false);
    setUserData(null);
    setCustomerToken(null);
    localStorage.removeItem("customerToken");

    console.log("remove customer data function");
  }

  async function setBusinessResponse(slug){
    setSlugData(slug);

    const response = await api.getResponse(slug, (apiToken || localStorage.getItem("apiToken")));

    setBusinessData(response.business);
    setUseChatBot(response.chatbot_enabled);
    setpersonalizationData(response.personalization);
    setHomeAboutData(response.home_about);
    setHomeProfessionalsData(response.home_professionals);
    setHomeServicesData(response.home_services);
    setHomePriceServicesData(response.home_price_services);
    setServicesData(response.services);
    setProductsData(response.products);
    setAboutData(response.about);
    setFeedbacksData(response.clients_feedbacks);
    setContactData(response.contact);
    setBusinessAvailabilityData(response.business_availability);
    setProfessionalsData(response.professionals);

    console.log(userData);
    console.log("user data aqui");

    if(userData){
      console.log("tem user data");

      if(userData.business_establishment_id != response.business.id){
        console.log("business id diferente");
        await removeCustomerData();
      }
    }
  }

  async function validateWhatsAppCode(phone, code, slug){
    const response = await api.validateWhatsAppCode((apiToken || localStorage.getItem("apiToken")), phone, code, slug);

    console.log(response);

    return response;
  }

  return (
    <UserContext.Provider
      value={{
        validateApiToken,
        validateCustomerToken,
        apiToken,
        setApiToken,
        customerToken,
        setCustomerToken,
        loadingUserValidation,
        setLoadingUserValidation,
        userIsLoggedIn,
        setUserIsLoggedIn,
        userIsNewUser,
        setUserIsNewUser,
        userData,
        setUserData,
        businessData,
        slugData,
        personalizationData,
        homeAboutData,
        servicesData,
        productsData,
        aboutData,
        homeProfessionalsData,
        homeServicesData,
        homePriceServicesData,
        feedbacksData,
        contactData,
        setSlugData,
        setBusinessResponse,
        businessAvailabilityData,
        professionalsData,
        removeCustomerData,
        useChabotBot,
        validateWhatsAppCode
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
