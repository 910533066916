import { useUserContext } from "../../contexts/UserContext";

import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";

export default function ContactInfo() {

  const { contactData } = useUserContext();

  const whastAppLink = `https://api.whatsapp.com/send?phone=+55${contactData?.whatsapp.replace(/\D/g, '')}`;

  return (
    <section className="info-box section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="section-head mb-30">
              <div className="section-subtitle">Informações de contato</div>
              <div className="section-title mb-20">Fale com a gente</div>
              
              <p style={{color: "var(--main-color-3)"}}>Caso tenha alguma dúvida, entre em contato com a gente!</p>
            </div>
               
            <div className="item"> <span className="icon"><FaMapMarkerAlt /></span>
              <div className="cont">
                <h5 style={{color: "var(--main-color-1)"}}>Endereço</h5>
                <p style={{color: "var(--main-color-3)"}}>{contactData?.address} - {contactData?.cep}</p>
              </div>
            </div>

            <div className="item"> <span className="icon"><FaPhoneAlt /></span>
              <div className="cont">
                <h5 style={{color: "var(--main-color-1)"}}>Telefone</h5>
                <p style={{color: "var(--main-color-3)"}}><a href={"tel:"+contactData?.phone}>{contactData?.phone}</a></p>
              </div>
            </div>

            <div onClick={() => window.open(whastAppLink, "_blank")} style={{cursor: "pointer"}} className="item"> <span className="icon"><FaWhatsapp /></span>
              <div className="cont">
                <h5 style={{textDecoration: "underline", color: "var(--main-color-1)"}} >WhatsApp</h5>
                <p style={{color: "var(--main-color-3)"}}>{contactData?.whatsapp}</p>
              </div>
            </div>

            <div className="item"> <span className="icon"><MdEmail /></span>
              <div className="cont">
                <h5 style={{color: "var(--main-color-1)"}}>Email</h5>
                <p style={{color: "var(--main-color-3)"}}>{contactData?.email ? contactData.email : "-"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}