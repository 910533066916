import {
  Container,
  LeftArrow,
  RightArrow,
  ScrollableDiv,
  Timetable,
  ButtonContainer,
  Price,
  Duration,
} from "./style";
import { sumPrices, sumDurations } from "../auxiliarFunctions";
import ScrollContainer from "react-indiana-drag-scroll";

import Button from 'react-bootstrap/Button';

export default function ScheduleContainer({
  scheduleArray,
  scrollX,
  setScrollX,
  selectedTime,
  setSelectedTime,
  reservationsList,
  handleReservation
}) {

  const isDesktop = window.innerWidth >= 1024;

  function handleLeftArrow() {
    let margin = scrollX + 350;
    if (margin > 0) {
      margin = 0;
    }
    setScrollX(margin);
  }

  function handleRightArrow() {
    let margin = scrollX - 350;
    //array size * (Timetable size + gap size) - last gap
    let listWidth = scheduleArray.length * (94 + 20) - 20;
    const ScrollableDivSize = 469.797;

    if (ScrollableDivSize - listWidth > margin) {
      margin = ScrollableDivSize - listWidth;
    }
    setScrollX(margin);
  }

  function renderScheduleTimes() {
    return scheduleArray?.map((time) => (
      <Timetable fc={"white"} key={time} onClick={() => setSelectedTime(time)}>
        {time}
      </Timetable>
    ));
  }

  return (
    <>
      <Container>
        <LeftArrow fc={"white"} onClick={handleLeftArrow} />
        <RightArrow fc={"white"} onClick={handleRightArrow} />

        <ScrollableDiv>
          {isDesktop && (
            <div
              className="inside-scroll"
              style={{
                marginLeft: scrollX,
                width: scheduleArray?.length * 114 - 20,
                transition: "all ease 0.9s",
              }}
            >
              {renderScheduleTimes()}
            </div>
          )}
          {!isDesktop && (
            <ScrollContainer className="inside-scroll">
              {renderScheduleTimes()}
            </ScrollContainer>
          )}
        </ScrollableDiv>
      </Container>
      {selectedTime !== "" && (
        <ButtonContainer>
          <div style={{width: '46%', display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
            <Price fc={"white"}>{`R$ ${sumPrices(reservationsList)}`}</Price>
            <Duration dc={"white"}>{sumDurations(reservationsList)}</Duration>
          </div>
          <Button style={{fontSize: "1.275rem", fontWeight: "800", lineHeight: "1em", letterSpacing: "0.7px", padding: "10px", width: '46%'}} variant="outline-light"
            onClick={handleReservation}
          ><div> Agendar horário </div> - {selectedTime}</Button>
        </ButtonContainer>
      )}
    </>
  );
}
