import { useUserContext } from "../../contexts/UserContext";
import { priceFormat } from "../ReservationModal/auxiliarFunctions";

function dividirEmGrupos(arr, tamanhoDoGrupo) {
  let grupos = [];
  for (let i = 0; i < arr.length; i += tamanhoDoGrupo) {
    grupos.push(arr.slice(i, i + tamanhoDoGrupo));
  }
  return grupos;
}

export default function HomePriceServicesSection() {
  const { homePriceServicesData, servicesData } = useUserContext();

  if(!homePriceServicesData?.show_session) return <></>;
  

  return (
    <section className="barber-pricing section-padding position-re">
    <div className="container">
        <div className="row">
            <div className="row">
                <div className="col-md-12">
                    <div className="section-head text-center">
                        <div className="section-title">{homePriceServicesData.title}</div>
                    </div>
                </div>
            </div>

            {servicesData && dividirEmGrupos(servicesData, 5).map((grupo, index) => (
              <div key={`group-${index}`} className="col-md-6">

                {grupo.map((service, index) => (
                  <div key={`group-${index}-item-${index}`} className="menu-list mb-30">
                  <div className="item">
                      <div className="flex">
                          <div className="title">{service.title}</div>
                          <div className="dots"></div>
                          <div className="price">{priceFormat(service.price)}</div>
                      </div>
                      <p><i>{service.description}</i></p>
                  </div>
                </div>
                ))}
              </div>
            ))}
        </div>
    </div>
</section>
  );
}