import { useUserContext } from "../../contexts/UserContext";

export default function AboutSection() {

  const { aboutData } = useUserContext();

  return (
    <section className="about section-padding" data-scroll-index="1">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mb-30">
            <div className="section-head mb-20">
              <div className="section-title">{aboutData?.title}</div>
            </div>
            
            <p style={{color: "var(--main-color-3)" }} dangerouslySetInnerHTML={{ __html: aboutData?.description.replace(/\r\n|\n/g, '<br />') }} />

          </div>
          <div className="col col-md-6 "> <img src={aboutData?.image?.url} alt="" className="mt-90 mb-30" /> </div>
        </div>
      </div>
    </section>
  );
}
