import {
  Title,
  Description,
  ButtonContainer,
  Price,
  Duration,
  Button,
  modalStyles,
} from "./style";
import Modal from "../Modal";

export default function ReadMoreModal({
  readMoreModalIsOpen,
  setReadMoreModalIsOpen,
  readMoreData,
  handleReservation
}) {

  function closeModal() {
    document.body.style.overflow = "unset";
    setReadMoreModalIsOpen(false);
  }

  const converterTempoParaTexto = (tempoEmMinutos) => {
    const horas = Math.floor(tempoEmMinutos / 60);
    const minutos = tempoEmMinutos % 60;
  
    if (horas === 0) {
      return `${minutos} minuto${minutos !== 1 ? 's' : ''}`;
    } else if (minutos === 0) {
      return `${horas} hora${horas !== 1 ? 's' : ''}`;
    } else {
      return `${horas} hora${horas !== 1 ? 's' : ''} e ${minutos} minuto${minutos !== 1 ? 's' : ''}`;
    }
  };

  const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  return (
    <Modal
      isOpen={readMoreModalIsOpen}
      ariaHideApp={false}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <Title>{readMoreData?.title}</Title>
      <Description>{readMoreData?.description}</Description>
      <ButtonContainer>
        <div>
          <Price>{`${numberFormat(readMoreData?.price)}`}</Price>
          <Duration>{converterTempoParaTexto(readMoreData?.duration)}</Duration>
        </div>
        <Button
          onClick={() => {
            closeModal();
            handleReservation(readMoreData);
          }}
        >
          Agendar
        </Button>
      </ButtonContainer>
    </Modal>
  );
}
