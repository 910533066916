import { useUserContext } from "../../contexts/UserContext";

export default function HomeToAboutSection() {
  const { homeAboutData } = useUserContext();

  return (
    homeAboutData && homeAboutData.show_session ? (
      <section className="about section-padding" data-scroll-index="1">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-30">
              <div className="section-head mb-20">
                <div className="section-title">{homeAboutData?.title}</div>
              </div>
              
              <p style={{color: "var(--main-color-3)" }} dangerouslySetInnerHTML={{ __html: homeAboutData?.description.replace(/\r\n|\n/g, '<br />') }} />
 
            </div>
            <div className="col col-md-3" data-animate-effect="fadeInUp"> <img src={homeAboutData?.image?.url} alt="" className="mt-90 mb-30" /> </div>
            <div className="col col-md-3" data-animate-effect="fadeInUp"> <img src={homeAboutData?.image_2?.url} alt="" /> </div>
          </div>
        </div>
      </section>
    ) : <></>
  );
}