import { memo, useEffect, useState } from "react";
import { useUserContext } from "../../contexts/UserContext";
import HeaderSection from "../../components/HeaderSection";
import ServicesSection from "../../components/ServicesSection";
import ProductsSection from "../../components/ProductsSection";
import ReadMoreModal from "../../components/ReadMoreModal";
import ReservationModal from "../../components/ReservationModal";
import { useCallback } from "react";
import { useAuthModalContext } from "../../contexts/AuthModalContext";
import { useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';

import { Filters } from "./style";

function ServicesPage() {

  const { servicesData, productsData, setBusinessResponse, slugData, apiToken, validateApiToken, userIsLoggedIn } = useUserContext();
  const { slug } = useParams();

  useEffect(async () => {
    if(!apiToken){
      await validateApiToken(apiToken);
    }
    
    if(!slugData){
      setBusinessResponse(slug);
    }
  }, []);

  const { openAuthenticationModal } = useAuthModalContext();

  const [readMoreModalIsOpen, setReadMoreModalIsOpen] = useState(false);
  const [reservationModalIsOpen, setReservationModalIsOpen] = useState(false);
  const [readMoreData, setReadMoreData] = useState();
  const [reservationsList, setReservationsList] = useState([]);
  const [isChoosingMoreServices, setIsChoosingMoreServices] = useState(false);

  const [objectsFilter, setObjectsFilter] = useState("");

  const [filterCategoryValue, setFilterCategoryValue] = useState(0);

  const filteredServices = servicesData?.filter(service =>
    filterCategoryValue != 2 && service.title.toLowerCase().includes(objectsFilter.toLowerCase())
  );

  const filteredProducts = productsData?.filter(product =>
    filterCategoryValue != 1 && product.title.toLowerCase().includes(objectsFilter.toLowerCase())
  );


  const readMore = useCallback((service) => {
    setReadMoreModalIsOpen(true);
    document.body.style.overflow = "hidden";
    setReadMoreData(service);
  }, []);

  const handleFilterCategory = (event) => {
    setFilterCategoryValue(event.target.value);
  };

  const handleFilterChange = (e) => {
    setObjectsFilter(e.target.value);
  };


  const handleReservation = useCallback(
    (service) => {
      if (!userIsLoggedIn) {
        openAuthenticationModal();
        return;
      }
      console.log(service);
      setReservationsList((prev) => [...prev, service]);
      setReservationModalIsOpen(true);
      document.body.style.overflow = "hidden";
      return;
    },
    [userIsLoggedIn]
  );

  return (
    <>
      <HeaderSection page={"services"} title="Serviços & Produtos" />

      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'grid', backgroundColor: "var(--main-color-4)"}}>
        <div style={{alignItems: "center", justifyContent: "center", display: "grid"}}>
          <div style={{marginTop: '10px'}}>
            <h3 style={{color: "var(--main-color-1)"}}>Filtrar</h3>
          </div>
        </div>

        <Filters>
          <Form>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gridColumnGap: "15px"}}>
              <Form.Group controlId="exampleForm.ControlInput1" style={{marginRight: "5px", width: "100%"}}>
                <Form.Control value={objectsFilter} onChange={handleFilterChange} style={{fontWeight: "400", lineHeight: "1.5", height: "100%", color: "var(--bs-body-color)", backgroundColor: "var(--bs-body-bg)", border: "var(--bs-border-width) solid var(--bs-border-color)"}} type="text" placeholder="Pesquisar Item" />
              </Form.Group>

              <Form.Select aria-label="Default select example" value={filterCategoryValue} onChange={handleFilterCategory}>
                <option value={0}>Filtrar por todos</option>
                <option value={1}>Serviços</option>
                <option value={2}>Produtos</option>
              </Form.Select>
            </div>
          </Form>
        </Filters>
       </div>


      {filterCategoryValue != 2 ? (<ServicesSection
        readMore={readMore}
        handleReservation={handleReservation}
        isChoosingMoreServices={isChoosingMoreServices}
        servicesData={filteredServices}
      />) : <></>}

      {filterCategoryValue != 1 ? (<ProductsSection
        readMore={readMore}
        handleReservation={handleReservation}
        isChoosingMoreServices={isChoosingMoreServices}
        productsData={filteredProducts}
      />) : <></>}
     
    
      <ReadMoreModal
        readMoreModalIsOpen={readMoreModalIsOpen}
        setReadMoreModalIsOpen={setReadMoreModalIsOpen}
        readMoreData={readMoreData}
        handleReservation={handleReservation}
      />

      <ReservationModal
        reservationModalIsOpen={reservationModalIsOpen}
        setReservationModalIsOpen={setReservationModalIsOpen}
        reservationsList={reservationsList}
        setReservationsList={setReservationsList}
        setIsChoosingMoreServices={setIsChoosingMoreServices}
      />
    </>
  );
}

export default memo(ServicesPage);
