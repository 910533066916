import React, { useEffect, useState } from "react";

import { useUserContext } from "../../contexts/UserContext";

import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

import defaultProductImage from "../../assets/default_professional.jpg";

export default function HomeProfessionalsSection() {
  const { homeProfessionalsData, professionalsData } = useUserContext();

  if(!homeProfessionalsData?.show_session) return <></>;

  return (
    <section style={{paddingTop: "15px"}} className="team section-padding pb-0">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-head text-center">
              <div className="section-title white">{homeProfessionalsData?.title}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="owl-carousel owl-theme">

              {professionalsData?.filter((professional) => professional.show_home_session).map((professional, index) => (
                <div key={index} className="team-card mb-30">
                  <div className="team-img"><img src={professional.image && professional.image.url ? professional.image.url : defaultProductImage} alt="" className="w-100"/></div>
                  <div className="team-content">
                    <h3 className="team-title">{professional.name}<span>{professional.position}</span></h3>
                    <p className="team-text">{professional.about}</p>
                    {professional.phone || professional.facebook || professional.instagram ? (
                    <div className="social">
                      <div className="full-width">
                        {professional.phone ? (<a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send/?phone=${professional.phone.replace(/[^0-9]/g, "")}&text=Olá`}><FaWhatsapp /></a>) : (<></>)}
                        {professional.facebook ? (<a rel="noreferrer" target="_blank" href={professional.facebook}><FaFacebookF /></a>) : (<></>)}
                        {professional.instagram ? (<a rel="noreferrer" target="_blank" href={professional.instagram}><FaInstagram /></a>) : (<></>)}
                      </div>
                    </div>) : (<></>) }
                  </div>
                  <div className="title-box">
                    <h3 className="mb-0">{professional.name}<span>{professional.position}</span></h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}