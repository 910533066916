import { memo } from "react";

import './title.css';

function TitleSession({ page, title, businessData, slugData }) {
  return (
    <>
      {page === "home" ? (
        <>
          <div className="banner-header full-height valign bg-img bg-fixed">
            <div className="container">
                <div className="row content-justify-center">
                    <div className="col-md-12 text-center">
                        <div className="align-title">
                            <h1>{businessData?.name.toUpperCase()}</h1>
                            <h5>{businessData?.slogan}</h5>
                            <a href={"/"+slugData+"/servicos"} className="button-1 mt-20">Agende seu horário<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </>
      ) : (
        <div className="banner-header full-height valign bg-img bg-fixed">
          <div className="container">
            <div className="row content-justify-center">
              <div className="col-md-12 text-center">
                <div className="v-middle">
                  <h3>{title.toUpperCase()}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default memo(TitleSession);
