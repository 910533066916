import React, { useEffect, useState } from "react";

import { useUserContext } from "../../contexts/UserContext";

import defaultServiceImage from "../../assets/default-service.webp";

import { priceFormat } from "../ReservationModal/auxiliarFunctions";

export default function HomeProfessionalsSection() {
  const { homeServicesData, servicesData } = useUserContext();

  if(!homeServicesData?.show_session) return <></>;

  return (
    <section className="barber-services section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-head text-center">
              <div className="section-title">{homeServicesData?.title}</div>
            </div>
          </div>
        </div>
        <div className="row">
          {servicesData?.filter((service) => service.show_home_session).map((service, index) => (
            <div key={index} className="col-md-4" data-animate-effect="fadeInUp">
              <div className="item">
                <div className="position-re o-hidden"> <img src={service.image && service.image.url ? service.image.url : defaultServiceImage} alt=""/> </div>
                  <div className="con">
                    <div className="icon icon-icon-1-6"></div>
                    <h5>{service.title}</h5>
                    <div className="line"></div>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div className="permalink">{service.description}</div>
                        <h6>{priceFormat(service.price)}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>)) }
            </div>
        </div>
    </section>
  );
}