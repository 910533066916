import { useRef, useState } from "react";
import { useUserContext } from "../../contexts/UserContext";
import * as api from "../../services/api";
import renderToast from "../../utils/renderToast";
import { renderDotsLoading } from "../../utils/renderDotsLoading";
import {
  Container,
  Title,
  InputContainer,
  FieldName,
  Input,
  Action,
} from "./style";
import handleApiErrors from "../../utils/handleApiErrors";
import PhoneNumberInput from "../PhoneNumberInput";
import { memo } from "react";

import InputMask from 'react-input-mask';

function ProfileConfigSection() {
  const { userData, setUserData, apiToken, slugData } = useUserContext();

  const nameRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const cpfRef = useRef(null);
  const birthdateRef = useRef(null);

  const [changingField, setChangingField] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function changeInformation(field) {
    if (changingField !== field) return setChangingField(field);

    setChangingField("");

    let input = "";

    if (field === "name") {
      input = nameRef.current.value;
    }
    
    if(field === "phone"){
      input = phoneRef.current.value;
    }
      
    if(field === "email"){
      input = emailRef.current.value;
    }

    if(field === "password"){
      input = passwordRef.current.value;
    }

    if(field === "cpf"){
      input = cpfRef.current.value;
    }

    if(field === "birthdate"){
      input = birthdateRef.current.value;
    }

    nameRef.current.value = "";
    phoneRef.current.value = "";
    emailRef.current.value = "";
    passwordRef.current.value = "";
    cpfRef.current.value = "";
    birthdateRef.current.value = "";

    setIsLoading(true);
    const response = await api.updateUser(apiToken, slugData, userData.id, { [field]: input }, field);
    setIsLoading(false);

    if (response.status === 200) {
      setUserData({ ...userData, [field]: input });
      renderToast("success", "Alterado com sucesso!");
      return;
    }

    handleApiErrors(response);
    return;
  }

  function renderButton(field) {
    if (changingField === field) {
      if (isLoading) return renderDotsLoading();
      return "Salvar";
    }

    return "Alterar";
  }

  let formatedBirthDate = "";

  if(userData && userData.birthdate){
    const userBirthDate = new Date(userData.birthdate + "T00:00:00Z");
    formatedBirthDate = `${userBirthDate.getUTCDate().toString().padStart(2, '0')}/${(userBirthDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${userBirthDate.getUTCFullYear()}`;
  }

  return (
    <Container style={{padding: '10px'}}>
      <h1 style={{textAlign: 'center', color: "var(--main-color-1)"}} >Informações da conta</h1>

      <InputContainer>
        <FieldName>Nome</FieldName>
        <Input placeholder={changingField === "name" ? "" : userData?.name} ref={nameRef} disabled={changingField === "name" && !isLoading ? false : true}/>
        <Action onClick={() => changeInformation("name")}>
          {renderButton("name")}
        </Action>
      </InputContainer>
      
      <InputContainer>
        <FieldName>Celular</FieldName>
        <PhoneNumberInput
          placeholder={changingField === "phone" ? "" : userData?.phone}
          reference={phoneRef}
          disabled={changingField === "phone" && !isLoading ? false : true}
        />
        <Action onClick={() => changeInformation("phone")}>
          {renderButton("phone")}
        </Action>
      </InputContainer>

      <InputContainer>
        <FieldName>Email</FieldName>
        <Input placeholder={changingField === "email" ? "" : userData?.email} ref={emailRef} disabled={changingField === "email" && !isLoading ? false : true}/>
        <Action onClick={() => changeInformation("email")}>
          {renderButton("email")}
        </Action>
      </InputContainer>

      <InputContainer>
        <FieldName>CPF</FieldName>

        <InputMask mask='999.999.999-99' disabled={changingField === "cpf" && !isLoading ? false : true}>
          {() => <Input disabled={changingField === "cpf" && !isLoading ? false : true} ref={cpfRef} placeholder={changingField === "cpf" ? "" : userData?.cpf} />}
        </InputMask>

        <Action onClick={() => changeInformation("cpf")}>
          {renderButton("cpf")}
        </Action>
      </InputContainer>

      <InputContainer>
        <FieldName>Data de Nascimento</FieldName>
        
        <InputMask mask='99/99/9999' disabled={changingField === "birthdate" && !isLoading ? false : true}>
          {() => <Input disabled={changingField === "birthdate" && !isLoading ? false : true} ref={birthdateRef} placeholder={changingField === "birthdate" ? "" : formatedBirthDate} />}
        </InputMask>
        
        <Action onClick={() => changeInformation("birthdate")}>
          {renderButton("birthdate")}
        </Action>
      </InputContainer>

      <InputContainer>
        <FieldName>Senha</FieldName>
        <Input placeholder={changingField === "password" ? "" : "*********"} ref={passwordRef} disabled={changingField === "password" && !isLoading ? false : true}/>
        <Action onClick={() => changeInformation("password")}>
          {renderButton("password")}
        </Action>
      </InputContainer>

    </Container>
  );
}

export default memo(ProfileConfigSection);
