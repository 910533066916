import HeaderSection from "../../components/HeaderSection";
import AboutSection from "../../components/AboutSection";
// import CommentsSection from "../../components/CommentsSection";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { useEffect } from "react";

export default function AboutPage() {

  const { setBusinessResponse, slugData, apiToken, validateApiToken } = useUserContext();
  const { slug } = useParams();

  useEffect(async () => {
    if(!apiToken){
      await validateApiToken(apiToken);
    }
    
    if(!slugData){
      setBusinessResponse(slug);
    }
  }, []);

  return (
    <>
      <HeaderSection page="about" title="Sobre" />
      <AboutSection />
    </>
  );
}
