import HeaderSection from "../../components/HeaderSection";

import * as variables from "../../styles/variables";

export default function NotFoundPage() {

  const footer = document.getElementsByClassName('contact-description');

  if(footer.length){
    footer[0].style.display = "none"
  }

  return (
    <>
      <section className="comming section-padding text-center">
        <div className="v-middle">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h1>404</h1>
                        <h2>Not Found!</h2>
                        <h6>Sorry We Cant Find That Page!</h6>
                        <p>The page you are looking for was moved, removed, renamed or never existed.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  );
}
