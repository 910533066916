import { Container, BackgroundImage, BackgroundDarkness } from "./style";
import { useNavigate, useLocation } from "react-router-dom";
import { useCallback, useState } from "react";
import { useUserContext } from "../../contexts/UserContext";
import HeaderMenu from "./HeaderMenu";
import TitleSession from "./Title/TitleSession";
import { useAuthModalContext } from "../../contexts/AuthModalContext";

export default function HeaderSection({ page, title }) {
  const { setCustomerToken, userIsLoggedIn, loadingUserValidation, businessData, slugData, userData, personalizationData } = useUserContext();

  const { openAuthenticationModal } = useAuthModalContext();

  const [profileTabIsOpen, setProfileTabIsOpen] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navTo = useCallback((page) => {
    if (pathname !== page) {
      setProfileTabIsOpen(false);
      navigate(page);
    }
  }, []);

  const logout = useCallback(() => {
    //navigate("/"+slugData);
    setProfileTabIsOpen(false);
    localStorage.removeItem("customerToken");
    setCustomerToken(null);
    window.location.reload();
  }, []);

  return (
    <Container page={page}>
      {personalizationData?.wallpaper?.url ? <BackgroundImage src={`${personalizationData?.wallpaper.url}`} alt="" /> : <></>}
      <BackgroundDarkness />

      <HeaderMenu 
        openAuthenticationModal={openAuthenticationModal}
        navTo={navTo}
        userIsLoggedIn={userIsLoggedIn}
        loadingUserValidation={loadingUserValidation}
        profileTabIsOpen={profileTabIsOpen}
        setProfileTabIsOpen={setProfileTabIsOpen}
        logout={logout}
        businessData={businessData}
        slugData={slugData}
        userData={userData}
        personalizationData={personalizationData}
      />

      <TitleSession page={page} title={title} navTo={navTo} businessData={businessData} slugData={slugData}/>
    </Container>
  );
}
