import {
  Container,
  Message,
  Services,
  Service,
  Summary,
  CancelButton,
  InfoButton,
} from "./style";
import { BsWhatsapp } from "react-icons/bs";
import dayjs from "dayjs";
import { memo } from "react";
import { useUserContext } from "../../contexts/UserContext";

import Button from 'react-bootstrap/Button';

function ProfileReservationsSection({
  reservationsArray,
  openCancelConfirmation,
}) {
  const { contactData } = useUserContext();

  const getWhatsappLink = (contact) => {
    if(contact && contact.whatsapp){
      return `https://api.whatsapp.com/send?phone=+55${contactData?.whatsapp.replace(/\D/g, '')}`;
    }else{
      return "";
    }
  }

  function formatDate(startTime, endTime) {
    const start = dayjs(startTime);
    const end = dayjs(endTime);

    return (
      end.format("DD/MM/YYYY") +
      " - " +
      start.format("HH:mm") +
      " até " +
      end.format("HH:mm")
    );
  }

  return (
    <Container>
      {reservationsArray.length === 0 ? (
        <Message>Você não tem nenhum horário agendado</Message>
      ) : (
        <Services>
          {reservationsArray?.map((data) => {
            return (
              <Service key={data?.id}>
                <Summary>{data?.title}</Summary>
                <Summary>{formatDate(data?.start_time, data?.end_time)}</Summary>
                {data.status == 1 && (<InfoButton>
                  Novo agendamento
                </InfoButton>)}

                {data.status == 2 && (<InfoButton>
                  Agendamento cancelado
                </InfoButton>)}

                {data.status == 3 && (<InfoButton>
                  Agendamento confirmado
                </InfoButton>)}

                {data.status == 4 && (<InfoButton>
                  Agendamento concluído
                </InfoButton>)}
              </Service>
            );
          })}
        </Services>
      )}

      {contactData && contactData.whatsapp ? (
      <>
        <Message marginTop={"100px"}>
          Problemas com algum agendamento? Entre em contato:
        </Message>

        <Button style={{color: "var(--main-color-2)", backgroundColor: "var(--main-color-1)", width: "260px", height: "70px", fontSize: "0.875rem", fontWeight: "800", lineHeight: "1em",  letterSpacing: "0.7px", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px"}} variant="outline-dark" onClick={() => window.open(getWhatsappLink(contactData), "_blank")}>
          Enviar mensagem
          <BsWhatsapp style={{fontSize: "1.875rem", marginLeft: "10px"}} />
        </Button>
      </>) : (<></>) }
      
    </Container>
  );
}

export default memo(ProfileReservationsSection);
